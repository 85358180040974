import React from "react";
import PilotTop from "./PilotTop";
import PilotTopLeft from "./PilotTopLeft";

function BottomNav({ color, isVisible }) {
  return (
    <div>
      <div className="bottom-navbar" style={{ backgroundColor: color }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div className="Customers-iteam">
                <span>
                  <i className="bi bi-clock-fill"></i>
                </span>
                <h4>Made Today If Ordered Pre 1PM</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="Customers-iteam">
                <span>
                  <i className="bi bi-people-fill"></i>
                </span>
                <h4>1000's Of Satisfied Customers</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="Customers-iteam">
                <span>
                  <i className="bi bi-shop"></i>
                </span>
                <h4>In-Store Collection Available</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="Customers-iteam">
                <span>
                  <i className="bi bi-paypal"></i>
                </span>
                <h4>Pay Over 3 Months With PayPal</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ background: "#000", display: isVisible ? "block" : "none" }}
      >
        <div className="container">
          <div className="trust-row row">
            <div className="col-md-6 first">
              <PilotTop />
            </div>
            <div className="col-md-4">
              <PilotTopLeft />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomNav;
