import React, { useState } from "react";
import { apiRequest } from "../../utils/api";
import { errorToast, infoToast, successToast } from "../../utils/Toast";

function SellForm() {
  const [number, setNumber] = useState("");
  const [numberPrice, setNumberPrice] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [town, setTown] = useState("");
  const [county, setCounty] = useState("");
  const [pin, setPin] = useState("");
  const [isOwner, setIsOwner] = useState(false);
  const [isRetention, setIsRetention] = useState(false);
  const [isVat, setIsVat] = useState(false);
  const [isCondition, setIsCondition] = useState(true);
  const [phone, setPhone] = useState("");

  async function submitForm(e) {
    e.preventDefault();
    try {
      if (
        !number ||
        !numberPrice ||
        !fullName ||
        !email ||
        !phone ||
        !isCondition
      )
        return infoToast("Please Fill All Fields");
      let res = await apiRequest.post(`/premium/seller/register`, {
        sell_number: number.toUpperCase(),
        sell_price: numberPrice,
        sell_owner: fullName,
        sell_retention_certificate: isRetention,
        seller_vat_register: isVat,
        seller_name: fullName,
        seller_email: email,
        seller_phone: phone,
        seller_address: address,
        seller_town: town,
        seller_country: county,
        seller_post_code: pin,
        seller_offer_registration: isCondition,
      });

      if (res.data.status === 200) {
        successToast("Your Number Has Been Added Successfully");
        setNumber("");
        setNumberPrice("");
        setFullName("");
        setPhone("");
        setEmail("");
        setAddress("");
        setTown("");
        setCounty("");
        setPin("");
        setIsCondition(true);
        setIsOwner(false);
        setIsRetention(false);
        setIsVat(false);
      } else {
        errorToast(res.data.message);
      }
    } catch (error) {
      errorToast(error);
    }
  }

  return (
    <div>
      <div className="sell-page">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="sell-card-row">
                <h3>Sell Your Number Plate</h3>
                <p>
                  Sell your number plate completely <span>FREE</span> with JDM
                  Plates.
                </p>
                <p>
                  If you are considering selling your private number plate then
                  have your car registration viewed by thousands of potential
                  customers by listing it with JDM Plates. We sell hundreds of
                  private number plates every week and earn some of the highest
                  possible prices for private number plate sales.
                </p>
                <p>
                  JDM Plates is the best place to sell your private number
                  plates with access to:
                </p>
                <ul>
                  <li>
                    <p>Reach over 1 million buyers a month</p>
                  </li>
                  <li>
                    <p>No fees or contract for selling your number plate</p>
                  </li>
                  <li>
                    <p>Easy to use platform for managing your sale</p>
                  </li>
                  <li>
                    <p>Fully managed ownership transfer</p>
                  </li>
                </ul>
                <p>
                  If you would like to sell your personalised number plate we
                  can help you make the entire sale and transfer of your car
                  registration quick and painless with our fully managed sales
                  process.
                </p>
                <p>
                  Once the sale of your private number plate is approved,
                  potential buyers will be able to view your plates online. As
                  soon as the purchase is confirmed we will notify you and begin
                  the transfer process to change ownership of the private plates
                  in a smooth and seamless transaction.
                </p>
                <div className="your-own">
                  <h2>Set Your Own Price</h2>
                  <p>
                    Have full control over the sales price of your private
                    number plates and decide how much you would like to sell
                    your personalised registration plates for. JDM Plates does
                    not provide valuations for private plates, but you can use
                    our search tool to browse similar registrations and set your
                    own price.
                  </p>
                  <p>
                    You are free to advertise your private plates at a price
                    comparable to similar registrations or set your price lower
                    or higher as you choose.
                  </p>
                </div>
                <div className="your-own">
                  <h2>Fees</h2>
                  <p>
                    Advertise your private number plates for sale with JDM
                    Plates and pay no fee for the sale or advertisement of your
                    registration plates. We will advertise your plates at the
                    price you set and add our commission on top. As part of the
                    sales transaction, the buyer will pay the commission.
                    Meaning, that there is absolutely no cost to you for selling
                    your private plates.
                  </p>
                  <p>
                    To list your private number plate for sale with JDM Plates,
                    please complete the form.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sell-registration-form">
                <h1>List Your Registration</h1>
                <div className="form-group ">
                  <label htmlFor="regNumber">Registration Number</label>
                  <input
                    style={{ fontFamily: " uknumberplate" }}
                    type="text"
                    className="form-control"
                    id="regNumber"
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="registration number"
                  />
                </div>
                <div className="col-auto last-bt">
                  <label className="sr-only" htmlFor="inlineFormInputGroup">
                    Listing Price
                    {/* Listing Price Inc VAT */}
                  </label>
                  <div className="input-group listing-price">
                    <div className="input-group-prepend">
                      <div className="input-group-text">£</div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="inlineFormInputGroup"
                      onChange={(e) => setNumberPrice(e.target.value)}
                      placeholder="Listing Price"
                    />
                  </div>
                </div>
                <ul className="use-serch">
                  <li>
                    <span>
                      <i className="bi bi-dot"></i>
                    </span>{" "}
                    <p>
                      Use the search facility on our website to look at the
                      price of similar registrations to yours to get an idea of
                      its valuation and price to set. You can increase/decrease
                      this amount at any time once listed.
                    </p>
                  </li>
                  <li>
                    <span>
                      <i className="bi bi-dot"></i>
                    </span>{" "}
                    <h3>Please note we do not offer valuations</h3>
                  </li>
                </ul>

                <div className="note-checkbox">
                  <ul>
                    <li>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue={isOwner}
                          checked={isOwner}
                          onChange={(e) => setIsOwner(!isOwner)}
                          id="flexCheckDefault1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault1"
                        >
                          I confirm I own this number plate
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue={isRetention}
                          id="flexCheckChecked2"
                          checked={isRetention}
                          onChange={(e) => {
                            setIsRetention(!isRetention);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked2"
                        >
                          Is It Held On Retention Certificate?
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue={isVat}
                          checked={isVat}
                          onChange={(e) => setIsVat(!isVat)}
                          id="flexCheckChecked3"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked3"
                        >
                          Are you selling this as a VAT registered business?
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="general-form">
                  <h2>General Info</h2>
                  <div className="form-group">
                    <label htmlFor="fullName">Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="Phone"
                    />
                  </div>
                  <h2>Address</h2>
                  <div className="form-group">
                    <label htmlFor="address">Street Address</label>
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="Address"
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="town">Town</label>
                        <input
                          type="text"
                          className="form-control"
                          id="town"
                          placeholder="Town"
                          onChange={(e) => setTown(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="county">County</label>
                        <input
                          type="text"
                          className="form-control"
                          id="county"
                          onChange={(e) => setCounty(e.target.value)}
                          placeholder="County"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="postCode">Postcode</label>
                        <input
                          type="text"
                          className="form-control"
                          id="postCode"
                          onChange={(e) => setPin(e.target.value)}
                          placeholder="Postcode"
                        />
                      </div>
                    </div>
                    <div className="Permission-title">
                      <h2>Contact Permission</h2>
                      <p>
                        We'd love to send you exclusive offers, new and
                        information about our services by email.
                      </p>

                      <div className="note-checkbox bottom-bt">
                        <ul>
                          <li>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue={isCondition}
                                id="offers"
                                checked={isCondition}
                                onChange={(e) => setIsCondition(!isCondition)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="offers"
                              >
                                Yes please, i'd like to hear about offers and
                                services.
                                <br /> You can unsubscribe at any time.
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="submith-button-list">
                      <button
                        type="button"
                        className="btn btn-warning "
                        onClick={submitForm}
                      >
                        Submit Listing
                      </button>
                      <p>
                        This site is protected by reCAPTCHA and <br />
                        the Google <a href="https://www.jdmplates.co.uk/privacy-cookie-policy/" target="_blank">Privacy Policy</a> and{" "}
                        <a href="https://www.jdmplates.co.uk/terms-conditions/" target="_blank">Terms of Service</a> apply.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellForm;
