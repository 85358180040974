import React, { useEffect } from "react";
import { Home, MainSearch, NewPlates } from "./pages";
import { Route, Routes, useLocation } from "react-router-dom";
import ViewPlate from "./pages/ViewPlate";
import Sellpage from "./pages/Sellpage";
import Checkout from "./pages/Checkout";
import ThankYou from "./pages/ThankYou";
import Fail from "./pages/Fail";
import RegEnquiry from "./pages/RegEnquiry";

function Routing() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="search/:keyword" element={<MainSearch />} />
      <Route path="new-plates/:keyword/:number" element={<NewPlates />} />
      <Route path="prefix/:keyword/:number" element={<NewPlates />} />
      <Route path="old/:keyword/:number" element={<NewPlates />} />
      <Route path="view/:number" element={<ViewPlate />} />
      <Route path="view-old/:number" element={<ViewPlate />} />
      <Route path="view-premium/:number" element={<ViewPlate />} />
      <Route path="view-prefix/:number" element={<ViewPlate />} />
      <Route path="sell" element={<Sellpage />} />
      <Route path="checkout" element={<Checkout />} />
      <Route path="reg-enquiry/:number" element={<RegEnquiry />} />
      <Route path="thanks" element={<ThankYou />} />
      <Route path="fail" element={<Fail />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default Routing;
