import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../../../utils/api";
import {
  extractNumber,
  oldPattern,
  prefixPattern,
} from "../../../utils/function";

function ViewNumber({ details }) {
  const navigate = useNavigate();
  const [commission, setCommission] = useState([]);

  const route = details?.route;

  let dvlaPrice = parseFloat(details?.price);
  let dvlaFee = 80;
  let bbb = (((dvlaPrice - dvlaFee) / 120) * 20).toFixed(2);
  let sellPrice = parseFloat(dvlaPrice - dvlaFee - bbb).toFixed(2);

  let singleCommissionObject = commission.filter(
    (c) => c.min_price <= dvlaPrice && c.max_price >= dvlaPrice
  )[0];

  let mainCommission = singleCommissionObject?.commission;

  let commissionPrice = (
    (parseFloat(sellPrice) * parseFloat(mainCommission)) /
    100
  ).toFixed(2);

  let newPrice = parseFloat(
    parseFloat(sellPrice) + parseFloat(commissionPrice)
  ).toFixed(2);

  useEffect(() => {
    // plates.map((p) => console.log(p));
    getCommissions();
  }, []);

  async function getCommissions() {
    try {
      let res = await apiRequest.get("/commission/all");
      if (res.data.status === 200) {
        setCommission(res.data.message);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <div className="view-number">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>WE'VE GOT YOUR NUMBER</h2>
              <button type="button" className="btn btn-warning got-button">
                {route == "view"
                  ? details?.fullNumber
                      ?.toUpperCase()
                      .replace(
                        extractNumber(details?.fullNumber),
                        `${extractNumber(details?.fullNumber)} `
                      )
                  : route == "view-old"
                  ? oldPattern(details?.fullNumber)
                  : prefixPattern(details?.fullNumber)}
              </button>
              <h3>
                Available Now:<span>£{newPrice}</span>
              </h3>
              <button
                onClick={() =>
                  navigate(`/${details?.route}/${details?.fullNumber}`)
                }
                type="button"
                className="btn btn-warning view-button"
              >
                view
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewNumber;
