import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Seller({ title, plates, plate }) {
  console.log(plates);
  const navigate = useNavigate();
  return (
    <>
      <div className="premium-registrations">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="premium-title">
                <img crossOrigin="anonymous" src="/img/vip-crown-fill.png" />
                <h2>{title}</h2>
              </div>
            </div>
          </div>
          <div className="premium-table">
            <div className="premium-table-head">
              <ul>
                <li>
                  <h2>initials</h2>
                </li>
                <li>
                  <h2>Price</h2>
                </li>
                <li>
                  <h2>Action</h2>
                </li>
              </ul>
            </div>
            <div className="premium-table-body">
              {plates?.length > 0
                ? plates.map((p) => (
                    <ul key={p.fullNumber}>
                      <li>
                        <div className="nik-button">
                          <button type="button" className="btn btn-warning">
                            {p.fullNumber?.toUpperCase()}
                          </button>
                        </div>
                      </li>
                      <li>
                        <h3>£{p.price}</h3>
                      </li>
                      <li>
                        <div className="view-button">
                          <Link
                            to={`/view-premium/${p.fullNumber}`}
                            className="red-link"
                          >
                            <button type="button" className="btn btn-warning">
                              View Plate
                            </button>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  ))
                : null}
            </div>
          </div>
          {plates.length >= 10 && (
            <div className="col-md-12">
              <div className="row mb-5">
                <div className="col-4"></div>
                <div className="view-more-button">
                  <button
                    className="btn btn-dark"
                    // onClick={() => navigate(`/${page}/${plate}/1`)}
                  >
                    View More
                  </button>
                </div>
                <div className="col-4"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Seller;
