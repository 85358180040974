const baseUrl = "https://www.jdmplates.co.uk";

const menus = [
  {
    first: "Motorcycle",
    last: "Plates",
    link: "smallest-motorcycle-number-plates/",
  },
  {
    first: "4D",
    last: "Plates",
    link: "4d-number-plates",
  },
  {
    first: "3D Gel",
    last: "Plates",
    link: "3d-number-plates",
  },
  {
    first: "HEX",
    last: "Plates",
    link: "hex-number-plates",
  },
  {
    first: "Short",
    last: "Plates",
    link: "mission-short-legal-number-plates",
  },
  {
    first: "Import",
    last: "Plates",
    link: "import-number-plates",
  },
  {
    first: "Electric Car",
    last: "Plates",
    link: "electric-car-number-plates",
  },
  {
    first: "Number Plate",
    last: "Shop",
    link: "shop",
  },
  {
    first: "Number Plate",
    last: "Builder",
    link: "shop/number-plate-builder",
  },
  {
    first: "Buy",
    last: "Private Reg",
    link: "",
  },
  {
    first: "Sell",
    last: "Your Reg",
    link: "",
  },
];

const numberPlateTypes = [
  {
    first: "Current Number",
    last: "Plates",
    link: "current-number-plates",
  },
  {
    first: "Dateless Number",
    last: "Plates",
    link: "dateless-number-plates",
  },
  {
    first: "Suffix Number",
    last: "Plates",
    link: "suffix-number-plates",
  },
  {
    first: "Prefix Number",
    last: "Plates",
    link: "prefix-number-plates",
  },
  {
    first: "Name Related",
    last: "Plates",
    link: "name-related-number-plates",
  },
];

export { menus, baseUrl, numberPlateTypes };
