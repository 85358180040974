import React, { useEffect, useState } from "react";

import HomeBanner from "../home/components/HomeBanner";
import AdvancedSearch from "../home/components/AdvancedSearch";
import PremiumRegistrations from "../home/components/PremiumRegistrations";
import ViewNumber from "../home/components/ViewNumber";
import { apiRequest } from "../../utils/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { extractNumber } from "../../utils/function";
import Loader from "../home/components/Loader";
import { addData, getData } from "../../utils/storage";
import { errorToast } from "../../utils/Toast";

function NewPlates() {
  const { number, keyword } = useParams();
  const { pathname } = useLocation();
  const [newStyles, setNewStyles] = useState([]);
  const [plate, setPlate] = useState(getData("search-plate"));
  const [page, setPage] = useState(number);
  const [nextPlates, setNextPlates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [searchNumber, setSearchNumber] = useState("");
  const navigate = useNavigate();
  const route = pathname.split("/")[1];
  useEffect(() => {
    submit();
    nextPage();
  }, [page]);

  let apiRoute = `${
    route === "old"
      ? "old/date-less/search"
      : route === "prefix"
      ? "prefix/prefix-style/search"
      : "plate/new-style/search"
  }`;

  async function submit() {
    setIsLoading(true);
    try {
      let res = await apiRequest.get(`/${apiRoute}/${plate}/${page}`);
      if (res.data.status === 200) {
        let result = res.data.message;

        setNewStyles(
          route === "prefix"
            ? result.map((r) => ({
                fullNumber: r.pp_fullNumber?.replace(
                  extractNumber(r.pp_fullNumber),
                  `${extractNumber(r.pp_fullNumber)} `
                ),
                price: r.pp_price,
              }))
            : route === "old"
            ? result.map((r) => ({
                fullNumber: r.old_fullNumber?.replace(
                  extractNumber(r.old_fullNumber),
                  ` ${extractNumber(r.old_fullNumber)}`
                ),
                price: r.old_price,
              }))
            : result.map((p) => ({
                price: p.price,
                fullNumber: p.fullNumber?.replace(
                  extractNumber(p.fullNumber),
                  `${extractNumber(p.fullNumber)} `
                ),
              }))
        );

        setSearchNumber(
          res.data?.message?.filter(
            (p) => p?.fullNumber?.toLowerCase() === plate?.toLowerCase()
          )
        );
      } else {
        setNewStyles([]);
        console.log(res.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }

    window.scrollTo(0, 600);
  }
  async function submitForm(e) {
    e.preventDefault();
    if (!plate) {
      setNewStyles([]);
      return errorToast("Please Enter Number");
    }
    addData("search-plate", plate);
    window.location.href = `/search/${plate}`;
  }
  async function nextPage() {
    try {
      let res = await apiRequest.get(
        `/${apiRoute}/${plate}/${parseInt(page) + 1}`
      );
      if (res.data.status === 200) {
        setNextPlates(res.data.message);
      } else {
        setNewStyles([]);
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className="home-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner-serch-bar">
                <div className="banner-serch-bar-title">
                  <h2>We Make Buying Registrations Fast & Easy...</h2>
                </div>
                <form onSubmit={submitForm}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter your initials, name, car or anything else..."
                      value={plate}
                      onChange={(e) => setPlate(e.target.value)}
                    />
                    <button className="btn btn-dark" type="submit">
                      Search
                    </button>
                  </div>
                </form>
                {/* <h3>Enter your initials, name, car or anything else...</h3> */}
                {/* <div className="rating-row">
                  <img crossOrigin="anonymous" src="/img/google.png" />
                  <h2>
                    Google Rating <strong>4.8</strong>
                  </h2>
                  <ul>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                  </ul>
                  <h6>See all our reviews</h6>
                </div> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="car-img">
                <img src="/img/number-car.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {searchNumber[0] && <AdvancedSearch details={searchNumber[0]} />}
      {searchNumber[0] && <ViewNumber details={searchNumber[0]} />}
      {newStyles?.length > 0 ? (
        <>
          <PremiumRegistrations
            title={`${
              route === "old"
                ? "Dateless"
                : route === "prefix"
                ? "PREFIX STYLE"
                : "NEW STYLES"
            } '${getData("search-plate")?.toUpperCase()}' REGISTRATIONS`}
            plates={newStyles}
          />
          <div className="container">
            <div className="row mb-5">
              <div className="col-4"></div>
              <div className="col-4"></div>
              <div className="col-4">
                <div className="view-button">
                  {page != 1 && (
                    <button
                      className="btn btn-dark"
                      onClick={(e) => {
                        window.location.href = `/${route}/${plate}/${
                          parseInt(page) - 1
                        }`;
                      }}
                    >
                      Previous
                    </button>
                  )}
                  {nextPlates.length > 0 ? (
                    <button
                      className="btn btn-warning"
                      onClick={(e) => {
                        window.location.href = `/${route}/${plate}/${
                          parseInt(page) + 1
                        }`;
                      }}
                    >
                      Next
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {/* for dark bg */}
      {false && (
        <div className="dark-bgPremiumRegistrations">
          <PremiumRegistrations />
        </div>
      )}
    </div>
  );
}

export default NewPlates;
