import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { errorToast, infoToast } from "../../../utils/Toast";
import { apiRequest } from "../../../utils/api";
import { useDispatch } from "react-redux";
import { updateCoupon } from "../../../store/slices/cartSlice";
import { numberSpacing } from "../../../utils/function";

function LastStep({ cart }) {
  const navigate = useNavigate();

  const [showDiv, setShowDiv] = useState(false);
  const [buttonText, setButtonText] = useState("Enter it here");
  const [couponName, setCouponName] = useState("");
  const [coupon, setCoupon] = useState(null);
  const [conditions, setConditions] = useState(false);
  const [couponValue, setCouponValue] = useState(0);
  const dispatch = useDispatch();
  const handleClick = () => {
    // setShowDiv(true);

    setShowDiv(!showDiv);
    if (showDiv) {
      setButtonText("Enter it here");
    } else {
      setButtonText("Cancel");
    }
  };

  async function checkout() {
    if (!conditions) return infoToast("Please Accept Terms And Conditions ");
    try {
      let payload = {
        order_by: localStorage.getItem("uuid"),
        order_fullnumber: cart.fullNumber,
        order_category: cart.category,
        order_price: cart.price,
        order_coupon: couponName.toLowerCase(),
        order_discount: couponValue,
        order_vat: cart.vat,
        order_type: cart.fastCheckout,
        order_dvlafee: cart.dvlaFee,
        order_total: cartTotal,
        order_status: "pending",
        plate_type: cart.plate_type_value,
        cart,
      };

      let res = await apiRequest.post(`/order/place-order`, payload);
      if (res.data.status === 200) {
        localStorage.setItem("order_id", res.data.order_id);

        let payload = {
          name: cart.fullNumber,
          order_id: res.data.order_id,
          total: cartTotal,
          user_id: localStorage.getItem("uuid"),
        };

        let result = await apiRequest.post(`/checkout/checkout`, {
          details: payload,
        });

        window.location.href = result.data.url;

        // navigate("/thanks");
      } else {
        errorToast(res.data.message);
      }
    } catch (error) {
      errorToast(error.message);
    }
  }

  async function subCoupon() {
    try {
      let res = await apiRequest.get(
        `/coupon/apply/${couponName.toLowerCase()}`
      );

      if (res.data.status === 200) {
        setCoupon(res.data.message);
        setCouponValue(
          (res.data.message.percentage / 100) * parseFloat(cart.price)
        );
        setShowDiv(false);
        setButtonText("Edit");
        dispatch(
          updateCoupon({
            coupon: couponName,
            coupon_value:
              (res.data.message?.percentage / 100) * parseFloat(cart?.price),
          })
        );
      } else {
        setCoupon(null);
        setCouponValue(0);
        dispatch(
          updateCoupon({
            coupon: null,
            coupon_value: null,
          })
        );
        errorToast("1" + res.data.message);
      }
    } catch (error) {
      errorToast(error.message);
    }
  }

  let singleTotal = parseFloat(cart.price) - parseFloat(couponValue);
  let cartTotal = (
    singleTotal +
    parseFloat(cart.dvlaFee) +
    parseFloat(cart.vat) +
    parseFloat(cart.fastCheckout) +
    parseFloat(cart.plate_type)
  ).toFixed(2);
  return (
    <>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingThree">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            <span>3</span>Payment & Summary
          </button>
        </h2>
        <div
          id="collapseThree"
          className="accordion-collapse collapse"
          aria-labelledby="headingThree"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div className="billing-details-contant">
              <div className="order-summary">
                <div className="order-summary-title">
                  <h2>Order Summary</h2>
                </div>
                <ul className="order-summary-list">
                  <li>
                    <h3>Registration {numberSpacing(cart.category, cart.fullNumber)}</h3>
                    <span>£{parseFloat(cart.price).toFixed(2)}</span>
                  </li>
                  <li>
                    <h3>VAT</h3>
                    <span>£{parseFloat(cart.vat).toFixed(2)}</span>
                  </li>
                  <li>
                    <h3>Compulsory DVLA Fee</h3>
                    <span>£{cart.dvlaFee}</span>
                  </li>
                  {cart.fastCheckout ? (
                    <li>
                      <h3>Registration Transfer Service</h3>
                      <span>£{cart.fastCheckout}</span>
                    </li>
                  ) : null}
                  <li>
                    <h3>Physical Number Plate(s)</h3>
                    <span>£{cart.plate_type}</span>
                  </li>

                  <li>
                    <div className="promofield">
                      <div className="promoTitle">
                        <h3>
                          {coupon ? coupon.coupon : "Have a promo code?"}
                          <a onClick={handleClick}>{buttonText}</a>
                        </h3>
                        <span>£{couponValue.toFixed(2)}</span>
                      </div>
                      {showDiv && (
                        <div className="promo-input">
                          <input
                            type="text"
                            onChange={(e) => setCouponName(e.target.value)}
                            className="form-control"
                            value={couponName}
                          />
                          <button
                            type="button"
                            onClick={subCoupon}
                            className="btn apply-btn"
                          >
                            Apply
                          </button>
                        </div>
                      )}
                    </div>
                  </li>
                  <li>
                    <h2>
                      Total:<span>£{cartTotal}</span>
                    </h2>
                  </li>
                </ul>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => setConditions(!conditions)}
                    defaultValue={conditions}
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    I have read and agree to the{" "}
                    <a
                      href="https://www.jdmplates.co.uk/terms-conditions"
                      target="_blank"
                    >
                      Terms and Conditions
                    </a>
                  </label>
                </div>
                {/* <div className="order-summary-title">
                  <h2>Payment Method</h2>
                </div> */}
                {/* <div className="debit-card">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault3"
                    >
                      Debit / Credit Card
                    </label>
                  </div>
                  <div className="debit-card-img">
                    <img src="/img/card.png" />
                  </div>
                </div> */}
                {/* <div className="debit-numbar">
                  <ul>
                    <li>
                      <input
                        type="text"
                        className="ccFormatMonitor"
                        placeholder="Card Number"
                      />
                    </li>
                    <li>
                      {" "}
                      <input
                        type="text"
                        id="inputExpDate"
                        placeholder="MM / YY"
                        maxLength={7}
                      />
                      <input
                        type="password"
                        className="cvv"
                        placeholder="CVV"
                      />
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="back-button">
              <button
                type="button"
                onClick={checkout}
                className="btn btn-warning"
              >
                Complete purchase
                <span>
                  <img src="/img/arrow-right.png" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LastStep;
