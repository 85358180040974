import React from "react";

function AdvancedSearch({ details }) {
  return (
    <>
      <div className="advancedsearch">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>
                Results for <span>{details?.fullNumber}</span>
              </h2>
              {/* <h3>
                View close matches below or use our{" "}
                <a href="#">advanced search.</a>
              </h3> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdvancedSearch;
