import React from "react";
import SellForm from "./components/SellForm";

function Sellpage() {
  return (
    <div>
      <SellForm />
    </div>
  );
}

export default Sellpage;
