import React from "react";

function CopyRight() {
  return (
    <div>
      <div className="copy-right-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="visa-list">
                <li>
                  <img crossOrigin="anonymous" src="/img/logos-01.png" />
                </li>
                <li>
                  <img crossOrigin="anonymous" src="/img/logos-02.png" />
                </li>
                <li>
                  <img crossOrigin="anonymous" src="/img/logos-03.png" />
                </li>
                <li>
                  <img crossOrigin="anonymous" src="/img/logos-04.png" />
                </li>
              </ul>

              <p className="mb-3">
                DVLA is a registered trademark of the Driver & Vehicle Licensing
                Agency. JDM Plates is not affiliated to the DVLA or DVLA
                Personalised Registrations. JDM Plates is a recognised reseller
                of DVLA registrations. JDM Plates is also registered with the
                DVLA to supply physical number plates, i.e. the actual acrylic
                plate.
              </p>
              {/* <a href="">Website Designed by PurpleWeb Creative</a> */}
              <p>
                © Hanley Trading LTD t/a JDM Plates | The Conge, Great Yarmouth,
                NR30 1PJ | DVLA RNPS No: 57790 | Company No: 11309345
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CopyRight;
