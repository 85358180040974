import { useState, useEffect } from "react";
import { apiRequest } from "../utils/api";
import { useParams } from "react-router-dom";
import { errorToast, successToast } from "../utils/Toast";

function RegEnquiry() {
  const [amount, setAmount] = useState("");
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [numberId, setNumberId] = useState("");
  const { number } = useParams();

  useEffect(() => {
    searchNumber();
  }, []);

  async function searchNumber() {
    try {
      let res = await apiRequest.get(`/premium/seller/single/${number}`);
      if (res.data.status == 200) {
        let { message } = res.data;
        setNumberId(message.premium.sell_id);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function submitOffer() {
    try {
      if (
        !firstName ||
        !lastName ||
        !email ||
        !phone ||
        !amount ||
        !number ||
        !numberId
      )
        return errorToast("Please Fill All Fields");

      if (email != confirmEmail)
        return errorToast("Email And Confirm Email Not Same");

      let res = await apiRequest.post(`/offer/create`, {
        bidder_first_name: firstName,
        bidder_last_name: lastName,
        bidder_email: email,
        bidder_phone: phone,
        bidder_price: amount,
        bid_status: "active",
        bidder_title: title,
        bidding_fullNumber: number,
        bidding_number_id: numberId,
      });

      if (res.data.status === 200) {
        successToast("Offer Submitted Successfully ");
        setAmount("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setConfirmEmail("");
        setPhone("");
      } else {
        errorToast(res.data.message);
      }
    } catch (error) {
      errorToast(error);
    }
  }
  return (
    <div>
      <div className="regenquiry-page">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="contact-numbar">
                <div className="numbar-button">{number}</div>
                <h2>Contact us about {number}</h2>
                <p>
                  Please fill in our enquiry form to find out more about this
                  reg. A member of staff will get back to you during office
                  hours to confirm availability and order process.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="make-offer-form">
                <div className="make-offer-title">
                  <h2>Make An Offer</h2>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-group listing-price">
                      <div className="input-group-prepend">
                        <div className="input-group-text">£</div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="inlineFormInputGroup"
                        placeholder="100"
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <ul className="reasonable-offers">
                      <li>
                        <h3>
                          IMPORTANT: Only reasonable offers will be considered.
                        </h3>
                      </li>
                      <li>
                        <p>
                          Once we have reviewed your offer amount, we will get
                          in touch as soon as possible.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setTitle(e.target.value)}
                      >
                        <option selected>select </option>
                        <option value="mr">Mr.</option>
                        <option value="miss">Miss</option>
                        <option value="mrs">Mrs</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="Name"
                            placeholder="First Name"
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="Surname"
                            placeholder="Surname"
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="9873732223"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Confirm Email"
                        onChange={(e) => setConfirmEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="contant-perimisson">
                      <p>
                        By submitting this offer, you acknowledge that you are
                        entering an agreement to purchase this registration
                        should your offer be accepted
                      </p>
                      <h3>Contact Permission</h3>
                      <p>
                        We'd love to send you exclusive offers, new and
                        information about our services by email.
                      </p>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultChecked={true}
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Yes please, i'd like to hear about offers and
                          services. You can unsubscribe at any time.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="offer-button">
                      <button
                        type="button"
                        onClick={submitOffer}
                        className="btn btn-warning"
                      >
                        submit offer
                      </button>
                      <p>
                        This site is protected by reCAPTCHA and the Google{" "}
                        <a
                          href="https://policies.google.com/privacy"
                          target="_blank"
                        >
                          Privacy Policy{" "}
                        </a>
                        and{" "}
                        <a
                          href="https://policies.google.com/terms"
                          target="_blank"
                        >
                          Terms of Service
                        </a>{" "}
                        apply.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegEnquiry;
