import React, { useEffect, useState } from "react";

import HomeBanner from "./components/HomeBanner";
import AdvancedSearch from "./components/AdvancedSearch";
import PremiumRegistrations from "./components/PremiumRegistrations";
import ViewNumber from "./components/ViewNumber";
import { apiRequest } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import Seller from "./components/Seller";
import Loader from "./components/Loader";
import Toast, { errorToast } from "../../utils/Toast";
import { extractNumber } from "../../utils/function";
import usePageTitleAndMeta from "../../hooks/usePageTitleAndMeta";
import { addData, getData } from "../../utils/storage";
import FeaturedRegistrations from "./components/FeaturedRegistrations";
import { useLayoutEffect } from "react";

function Home() {
  const [newStyles, setNewStyles] = useState([]);
  const [oldStyles, setOldStyles] = useState([]);
  const [getPrefixStyles, setGetPrefixStyles] = useState([]);
  const [allNumbers, setAllNumbers] = useState([]);
  const [oldAll, setOldAll] = useState([]);
  const [newAll, setNewAll] = useState([]);
  const [ppAll, setPpAll] = useState([]);
  const [preAll, setPreAll] = useState([]);
  const [premiums, setPremiums] = useState([]);
  const [plate, setPlate] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [allArray, setAllArray] = useState([]);

  const [searchNumber, setSearchNumber] = useState([]);
  const navigate = useNavigate();

  async function submit(e) {
    e.preventDefault();
    if (!plate) {
      setNewStyles([]);
      setOldStyles([]);
      setGetPrefixStyles([]);
      setPremiums([]);
      return errorToast("Please Enter Number");
    }
    setIsLoading(true);
    try {
      await getNewStyles(plate);
      await getOldStyles(plate);
      await getAllPrefixStyles(plate);
      await getPremiums(plate);
      window.scrollTo(0, 750);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function getPremiums(plate) {
    addData("search-plate", plate);
    let res = await apiRequest.get(`/premium/search/${plate}/1`);
    if (res.data.status === 200) {
      let result = res.data.message;

      let valid = result.filter((p) => p.sell_number_status === "APPROVED");

      let all = valid.map((r) => ({
        fullNumber: r.sell_number,
        price: r.admin_price,
        route: "view-premium",
      }));

      setPreAll(all);
      // setSearchNumber(
      //   searchNumber.length > 0
      //     ? searchNumber
      //     : [
      //         {
      //           fullNumber: number?.sell_number,
      //           price: number?.admin_price,
      //           route: "view-premium",
      //         },
      //       ]
      // );
      setPremiums(
        result
          .filter((p) => p.sell_number_status === "APPROVED")
          .map((r) => ({
            fullNumber: r.sell_number,
            price: r.admin_price,
          }))
      );
    } else {
      setNewStyles([]);
      console.log(res.data.message);
    }
  }
  async function getNewStyles(plate) {
    let res = await apiRequest.get(`/plate/new-style/search/${plate}/${page}`);
    if (res.data.status === 200) {
      let { message } = res.data;

      let value = message.map((p) => ({
        price: p.price,
        fullNumber: p.fullNumber?.replace(
          extractNumber(p.fullNumber),
          `${extractNumber(p.fullNumber)} `
        ),
      }));

      let valueSet = new Set(value);

      setNewStyles([...valueSet]);

      let all = res.data.message?.map((p) => ({
        price: p.price,
        fullNumber: p.fullNumber,
        route: "view",
      }));

      setNewAll(all);
      // let number = res.data?.message?.filter((p) => {
      //   console.log(p?.fullNumber?.replace(/\s+/g, "").toLowerCase());
      //   return (
      //     p?.fullNumber?.replace(/\s+/g, "").toLowerCase() ==
      //     plate.replace(/\s+/g, "").toLowerCase()
      //   );
      // })[0];
      // setSearchNumber(
      //   searchNumber.length > 0
      //     ? searchNumber
      //     : [
      //         {
      //           price: number.price,
      //           fullNumber: number.fullNumber,
      //           route: "view",
      //         },
      //       ]
      // );
    } else {
      setNewStyles([]);
      console.log(res.data.message);
    }
  }
  async function getOldStyles(plate) {
    let res = await apiRequest.get(`/old/date-less/search/${plate}/${page}`);
    if (res.data.status === 200) {
      let result = res.data.message;

      // setSearchNumber(
      //   result.filter(
      //     (p) =>
      //       p?.old_fullNumber?.toLowerCase() ===
      //       plate.replace(/\s+/g, "").toLowerCase()
      //   )
      // );

      setOldStyles(
        result.map((r) => ({
          fullNumber: r.old_fullNumber?.replace(
            extractNumber(r.old_fullNumber),
            ` ${extractNumber(r.old_fullNumber)}`
          ),
          price: r.old_price,
        }))
      );

      let all = result.map((r) => ({
        fullNumber: r.old_fullNumber,
        price: r.old_price,
        route: "view-old",
      }));

      setOldAll(all);

      // let number = res.data?.message?.filter(
      //   (p) => p?.old_fullNumber?.toLowerCase() === plate.toLowerCase()
      // )[0];
      // setSearchNumber(
      //   searchNumber.length > 0
      //     ? searchNumber
      //     : [{ ...number, route: "view-old" }]
      // );
    } else {
      setNewStyles([]);
      console.log(res.data.message);
    }
  }
  async function getAllPrefixStyles(plate) {
    let res = await apiRequest.get(
      `/prefix/prefix-style/search/${plate}/${page}`
    );
    if (res.data.status === 200) {
      let result = res.data.message;

      setGetPrefixStyles(
        result.map((r) => ({
          fullNumber: r.pp_fullNumber?.replace(
            extractNumber(r.pp_fullNumber),
            `${extractNumber(r.pp_fullNumber)} `
          ),
          price: r.pp_price,
        }))
      );

      let all = result.map((r) => ({
        fullNumber: r.pp_fullNumber,
        price: r.pp_price,
        route: "view-prefix",
      }));

      setPpAll(all);
      // let number = res.data?.message?.filter(
      //   (p) =>
      //     p?.pp_fullNumber?.replace(/\s+/g, "").toLowerCase() ===
      //     plate.replace(/\s+/g, "").toLowerCase()
      // )[0];

      // setSearchNumber(
      //   searchNumber.length > 0
      //     ? searchNumber
      //     : [{ ...number, route: "view-prefix" }]
      // );
    } else {
      setNewStyles([]);
      console.log(res.data.message);
    }
  }
  usePageTitleAndMeta("JDM Plates", "JDM Plates", "JDM Plates");

  useEffect(() => {
    localStorage.removeItem("uuid");
    localStorage.removeItem("cart-plate");
    localStorage.removeItem("plate-info");
    if (isLoading) document.title = "Searching.....";
    else document.title = "JDM Plates";
  }, [isLoading]);

  useLayoutEffect(() => {
    let allArr = [...newAll, ...oldAll, ...ppAll, ...preAll];
    setAllArray(allArr);
    setSearchNumber(
      allArr.filter(
        (r) =>
          r.fullNumber?.replace(/\s+/g, "").toLowerCase() ===
          plate?.replace(/\s+/g, "").toLowerCase()
      )
    );
  }, [newAll, ppAll, oldAll, preAll]);

  return (
    <div>
      {isLoading && <Loader />}
      <div className="home-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner-serch-bar">
                <div className="banner-serch-bar-title">
                  <h2>We Make Buying Registrations Fast & Easy...</h2>
                </div>

                <form onSubmit={submit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter your initials, name, car or anything else...
                      "
                      onChange={(e) => setPlate(e.target.value)}
                    />
                    <button className="btn btn-dark" type="submit">
                      Search
                    </button>
                  </div>
                </form>
                {/* <h3>Enter your initials, name, car or anything else...</h3> */}
                {/* <div className="rating-row">
                  <img crossOrigin="anonymous" src="/img/google.png" />
                  <h2>
                    Google Rating <strong>4.8</strong>
                  </h2>
                  <ul>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                  </ul>
                  <h6>See all our reviews</h6>
                </div> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="car-img">
                <img src="/img/number-car.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {searchNumber.length > 0 ? (
        <ViewNumber details={searchNumber[0]} />
      ) : null}
      {/* for new styles dark bg */}
      {newStyles.length > 0 ? (
        <>
          <div className="home-content-btns">
            <a href="#prefixStyle">Prefix</a>
            <a href="#premium">Premium</a>
            <a href="#dateless">Dateless</a>
          </div>
          <div className="dark-bgPremiumRegistrations">
            <PremiumRegistrations
              title={`NEW STYLE '${getData(
                "search-plate"
              )?.toUpperCase()}' REGISTRATIONS`}
              plates={newStyles}
              page={"new-plates"}
              plate={getData("search-plate")}
            />
          </div>
        </>
      ) : allArray.length === 0 ? (
        <FeaturedRegistrations />
      ) : null}
      {/* for prefix */}
      {getPrefixStyles.length > 0 ? (
        <div className="dark-bgPremiumRegistrations" id="prefixStyle">
          <PremiumRegistrations
            title={`PREFIX STYLE '${getData(
              "search-plate"
            )?.toUpperCase()}' REGISTRATIONS`}
            plates={getPrefixStyles}
            page={"prefix"}
            plate={getData("search-plate")}
          />
        </div>
      ) : null}
      {/* premiums */}
      {premiums.length > 0 ? (
        <div className="dark-bgPremiumRegistrations" id="premium">
          <Seller
            title={`PREMIUM ALL REGISTRATIONS`}
            plate={plate}
            plates={premiums}
          />
        </div>
      ) : null}
      {/* for old plates */}
      {oldStyles?.length > 0 ? (
        <div id="dateless">
          <PremiumRegistrations
            title={`Dateless '${getData(
              "search-plate"
            )?.toUpperCase()}' REGISTRATIONS`}
            plates={oldStyles}
            page={"old"}
            plate={getData("search-plate")}
          />
          <div className="container"></div>
        </div>
      ) : null}
    </div>
  );
}

export default Home;
