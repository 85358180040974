import React from "react";
import { baseUrl } from "./menus";

function TopNav() {
  return (
    <div>
      <div className="top-nav">
        <div className="call-nav-left">
          <a href="tel:01493%20230023">
            <span>
              <i className="bi bi-telephone"></i>
            </span>
            Call Us Today
          </a>
          <a href={`${baseUrl}/contact-us`} target="_blank">
            <span>
              <i className="bi bi-envelope"></i>
            </span>
            Email Us Today
          </a>
        </div>
        <div className="call-nav-center">
          {/* <h3>For 10% Off, Use Code JDMP10. Alternatively, Click HERE!</h3> */}
        </div>
        <div className="call-nav-right">
          <ul>
            <li>
              <span>
                 <a href="https://www.facebook.com/JDMRegPlates" target="_blank"><i className="bi bi-facebook"></i></a>
              </span>
            </li>
            <li>
              <span>
               <a href="https://twitter.com/JDM_Plates" target="_blank"><i className="bi bi-twitter"></i></a>
              </span>
            </li>
            <li>
              <span>
               <a href="https://www.instagram.com/jdm_plates/" target="_blank"><i className="bi bi-instagram"></i></a>
              </span>
            </li>
            <li>
              <span>
               <a href="https://www.linkedin.com/in/jdm-plates-5766a2161/" target="_blank"><i className="bi bi-linkedin"></i></a>
              </span>
            </li>
            <li>
              <span>
               <a href="https://www.pinterest.co.uk/JDM_Plates/" target="_blank"><i className="bi bi-pinterest"></i></a>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TopNav;
