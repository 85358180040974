import React, { useEffect, useState } from "react";
import { apiRequest } from "../utils/api";

function ThankYou() {
  const [searchPlate, setSearchPlate] = useState();
  const plate = JSON.parse(localStorage.getItem("cart-plate"));

  useEffect(() => {
    
    localStorage.removeItem("plate-info");
    if (plate.isPremium) {
      getPlate();
    }

    sendEmail();

    setTimeout(() => {
      localStorage.removeItem("cart-plate");
    }, 3000);
  }, []);

  async function sendEmail() {
    try {
      let res = await apiRequest.get(
        `/order/email/${localStorage.getItem("uuid")}`
      );
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getPlate() {
    try {
      let res = await apiRequest.get(
        `/premium/seller/single/${plate.fullNumber}`
      );

      if (res.data.status === 200) {
        setSearchPlate(res.data.message);

        const { sell_category } = res.data.message.premium;
        await apiRequest.put(`/order/update-cat`, {
          category: sell_category,
          order_id: localStorage.getItem("order_id"),
        });
        await apiRequest.put(`/premium/seller/update-status`, {
          newStatus: "sold",
          sellNumber: plate.fullNumber,
        });
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      <div className="thankyou-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="thank-content">
                <img src="/img/green-verified.gif" />
                <h2>ThankYou</h2>
                <h3>Order ID : {localStorage.getItem("order_id")}</h3>
                <a
                  href="https://www.jdmplates.co.uk/number-plate-builder"
                  target="_blank"
                >
                  Buy your custom number plates for your new registration here!
                </a>
                <p>Please check your email for order details.</p>
                <p>
                  Thank you for placing your order with us! We are delighted to
                  inform you that your order has been successfully placed and is
                  now being processed. We appreciate your business and look
                  forward to delivering your items promptly. Should you have any
                  questions, please don't hesitate to reach out.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
