import React, { useEffect, useState } from "react";
import ViewBanner from "./components/ViewBanner";
import TransferManagement from "./components/TransferManagement";
import CustomersThink from "./components/CustomersThink";
import About from "./components/About";
import { useLocation, useParams } from "react-router-dom";
import { apiRequest } from "../utils/api";
import { removeSpaces } from "../utils/function";
import { prefix } from "../utils/prefixList";

function ViewPlate() {
  const { number } = useParams();
  const { pathname } = useLocation();
  const route = pathname.split("/")[1];
  const [singlePlate, setSinglePlate] = useState(null);
  const [plateNumber, setPlateNumber] = useState();
  const [myNumber, setMyNumber] = useState();

  let apiRoute = `${
    route === "view-old"
      ? "old/date-less"
      : route === "view-prefix"
      ? "prefix/prefix-style"
      : route === "view-premium"
      ? "premium/seller/search"
      : "plate/new-style"
  }`;
  let pageRoute = `${
    route === "view-old"
      ? "Dateless"
      : route === "view-prefix"
      ? "Prefix"
      : route === "view-premium"
      ? "Premium"
      : "New Styles"
  }`;
  let pageType = `${
    route === "view-old"
      ? "Dateless"
      : route === "view-prefix"
      ? "Prefix"
      : route === "view-premium"
      ? "Premium"
      : "New Styles"
  }`;
  async function getPlate() {
    try {
      let res = await apiRequest.get(
        `/${apiRoute}/single/${
          apiRoute === "premium/seller/search" ? number : removeSpaces(number)
        }`
      );
      if (res.data.status === 200) {
        setSinglePlate(res.data.message);
        setPlateNumber(res.data.message?.fullNumber);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getPlate();
    localStorage.removeItem("plate-info");
  }, []);

  let man =
    pageType === "Dateless"
      ? `of any age`
      : pageType === "Prefix"
      ? prefix[plateNumber?.charAt(0)]
      : pageType === "Premium"
      ? ``
      : singlePlate?.numbers > 50
      ? `on or after September 20${
          parseInt(singlePlate?.numbers) - parseInt(50) < 9
            ? `0${parseInt(singlePlate?.numbers) - parseInt(50)}`
            : parseInt(singlePlate?.numbers) - parseInt(50)
        }`
      : `on or after March 20${singlePlate?.numbers}`;

  let manufactured =
    singlePlate?.age || singlePlate?.category
      ? singlePlate?.category === "dateless" ||
        singlePlate?.category === "other"
        ? `at any age`
        : singlePlate?.age === "prefix" || singlePlate?.category === "prefix"
        ? prefix[plateNumber?.charAt(0)]
        : singlePlate?.age > 50
        ? `on or after September 20${
            parseInt(singlePlate?.age) - parseInt(50) < 9
              ? `0${parseInt(singlePlate?.age) - parseInt(50)}`
              : parseInt(singlePlate?.age) - parseInt(50)
          }`
        : `on or after March 20${singlePlate?.age}`
      : man;

  return (
    <div>
      <ViewBanner
        manufactured={manufactured}
        page={pageType}
        search={singlePlate}
        route={route}
        price={singlePlate?.price}
        myNumber={singlePlate}
      />
      {/* <TransferManagement /> */}
      <CustomersThink />
      <About manufactured={manufactured} number={number} />
    </div>
  );
}

export default ViewPlate;
