import React, { useEffect } from "react";
import TrustBox from "./PilotNav";

function PilotReviews() {
  useEffect(() => {
    // const trustbox = document.getElementById("trustbox");
    // window.Trustpilot.loadFromElement(trustbox);
  }, []);
  return (
    <>
      <div className="trustpilot-section">
        <div className="premium-title">
          <h2>What Our Customers Think...</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <TrustBox />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PilotReviews;
