import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    fullNumber: "",
    category: "",
    price: 0,
    vat: 0,
    dvlaFee: 80,
    fastCheckout: 0,
    coupon: "",
    coupon_value: "",
    isPremium: false,
    plate_type: 0,
    plate_type_value: "",
  },
};

const appSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    updateFast: (state, action) => {
      state.data = { ...state.data, fastCheckout: action.payload };
    },
    updatePlate: (state, action) => {
      state.data = {
        ...state.data,
        plate_type: action.payload.price,
        plate_type_value: action.payload.value,
      };
    },
    updateCoupon: (state, action) => {
      state.data = {
        ...state.data,
        coupon: action.payload.coupon,
        coupon_value: action.payload.coupon_value,
      };
    },
  },
});

export const { setData, updateFast, updateCoupon, updatePlate } =
  appSlice.actions;

export default appSlice.reducer;
