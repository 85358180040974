let prefix = {
  A: "on or after August 1983.",
  B: "on or after August 1984.",
  C: "on or after August 1985.",
  D: "on or after August 1986.",
  E: "on or after August 1987.",
  F: "on or after August 1988.",
  G: "on or after August 1989.",
  H: "on or after August 1990.",
  J: "on or after August 1991.",
  K: "on or after August 1992.",
  L: "on or after August 1993.",
  M: "on or after August 1994.",
  N: "on or after August 1995.",
  P: "on or after August 1996.",
  R: "on or after August 1997.",
  S: "on or after August 1998.",
  T: "on or after March 1999.",
  V: "on or after September 1999.",
  W: "on or after March 2000.",
  X: "on or after September 2000.",
  Y: "on or after Match 2001.",
};

export { prefix };
