import React from "react";

function Loader() {
  return (
    <div>
      <div className="loading">
        <div className="loading-text">
          <span className="loading-text-words">L</span>
          <span className="loading-text-words">O</span>
          <span className="loading-text-words">A</span>
          <span className="loading-text-words">D</span>
          <span className="loading-text-words">I</span>
          <span className="loading-text-words">N</span>
          <span className="loading-text-words">G</span>
        </div>
        <img src="/img/yellow.gif"/>
      </div>
    </div>
  );
}

export default Loader;
