import React from "react";
import Routing from "./Routing";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import TopNav from "./utils/TopNav";
import BottomNav from "./utils/BottomNav";
import Navbar from "./utils/Navbar";
import Footer from "./utils/Footer";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <TopNav />
      <Navbar />
      <BottomNav color={`#CEB600`} isVisible={true} />
      <Routing />
      <Footer />
    </>
  );
}

export default App;
