import React from "react";

function CustomersThink() {
  return (
    <div>
      <div className="customer-gallery">
        <div className="container">
          <div className="row">
            <SingleReview
              review={
                " Very happy with small motorbike plate!Arrived quicklyCheers 👍 "
              }
              title={"Peter Wormsley"}
            />
            <SingleReview
              review={
                "  Excellent product came on time, well packed and good value for money  "
              }
              title={"Neil Ker"}
            />
            <SingleReview
              review={
                " Brilliant product delivered on time will definitely order again "
              }
              title={"James Stone"}
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="see-all-button">
                <a
                  href="https://g.page/r/CYf_zCwiLX4NEB0/review"
                  target="_blank"
                >
                  See All
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SingleReview({ review, title }) {
  return (
    <>
      <div className="col-md-4">
        <div className="customer-card">
          <ul className="customer-star">
            <li>
              <i className="bi bi-star-fill"></i>
            </li>
            <li>
              <i className="bi bi-star-fill"></i>
            </li>
            <li>
              <i className="bi bi-star-fill"></i>
            </li>
            <li>
              <i className="bi bi-star-fill"></i>
            </li>
            <li>
              <i className="bi bi-star-fill"></i>
            </li>
          </ul>
          <p>{review}</p>
          <h2>From: {title}</h2>
          <h3>
            <a href="https://g.page/r/CYf_zCwiLX4NEB0/review" target="_blank">
              {" "}
              See it on Google
            </a>
          </h3>
        </div>
      </div>
    </>
  );
}
export default CustomersThink;
