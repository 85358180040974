import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiRequest } from "../../../utils/api";

function FeaturedRegistrations() {
  const [plates, setPlates] = useState([]);
  const [getPlates, setGetPlates] = useState([]);
  const [page, setPage] = useState(1);
  let perPageLimit = 20;
  useEffect(() => {
    getSellPlates();
  }, []);
  async function getSellPlates() {
    try {
      let res = await apiRequest(`/premium/seller/front`);
      if (res.data.status === 200) {
        let plates = res.data.message;

        const finalArr = [
          ...plates.sort((a, b) => {
            const priceA = parseFloat(a.admin_price);
            const priceB = parseFloat(b.admin_price);
            return priceB - priceA; // For descending order
          }),
        ];
        setPlates(finalArr);
        setGetPlates(finalArr?.slice(0, perPageLimit));
      } else {
        setGetPlates([]);
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function goToNext() {
    setPage(page + 1);
    setGetPlates(
      plates.slice(page * perPageLimit, page * perPageLimit + perPageLimit)
    );
    window.scrollTo(0, 750);
  }
  function goToBack() {
    setPage(page - 1);
    setGetPlates(
      plates.slice(
        page === 2 ? page * perPageLimit - 40 : page * perPageLimit - 40,
        page * perPageLimit - perPageLimit
      )
    );
    window.scrollTo(0, 750);
  }

  // total pages
  let totalPages = Math.ceil(plates.length / perPageLimit);
  return (
    <>
      {getPlates.length > 0 ? (
        <div className="featured-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="premium-title">
                  <img crossOrigin="anonymous" src="/img/vip-crown-fill.png" />
                  <h2>Featured Registrations</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {getPlates.map((c, i) => (
                <div className="col-6 col-sm-6 col-md-4 col-lg-3 " key={i}>
                  <div className="plate_no-card">
                    <h2>{c.sell_number}</h2>
                    <div className="plate_no-detals">
                      <Link to={`/view-premium/${c.sell_number}`}>View</Link>
                      <h4>£{c.admin_price}</h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row mb-5">
                  <div className="col-4"></div>
                  <div className="view-more-button">
                    {page !== 1 ? (
                      <button
                        className="btn btn-dark"
                        onClick={() => goToBack()}
                      >
                        Previous
                      </button>
                    ) : null}
                    {totalPages !== page ? (
                      <button
                        className="btn btn-success"
                        onClick={() => goToNext()}
                      >
                        Next
                      </button>
                    ) : null}
                  </div>
                  <div className="col-4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default FeaturedRegistrations;
