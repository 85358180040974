import React from "react";
import PilotCheckout from "../../../utils/PilotCheckout";
import { numberSpacing } from "../../../utils/function";

function CartLeft({ cart, manufactured }) {
  let info = localStorage.getItem("plate-info");

  return (
    <>
      <div className="col-md-4">
        <div className="order-checkout-card">
          <div className="order-checkout-card-title">
            <h2>{numberSpacing(cart.category, cart.fullNumber)}</h2>
            <p>
              This registration is only suitable for vehicles manufactured{" "}
              <span>{info ? info : manufactured + "."}</span>
            </p>
          </div>
          <ul className="order-summary-list">
            <li>
              <h3>Registration:</h3>
              <span>£{parseFloat(cart.price).toFixed(2)}</span>
            </li>
            <li>
              <h3>VAT:</h3>
              <span>£{parseFloat(cart.vat).toFixed(2)}</span>
            </li>
            <li>
              <h3>Compulsory DVLA Fee:</h3>
              <span>£{cart.dvlaFee}</span>
            </li>
            {cart.fastCheckout ? (
              <li>
                <h3>Registration Transfer Service:</h3>
                <span>£{cart.fastCheckout}</span>
              </li>
            ) : null}
            <li>
              <h3>Physical Number Plate(s):</h3>
              <span>£{cart.plate_type}</span>
            </li>

            <li>
              <h3>Coupon{cart.coupon ? `(${cart.coupon})` : ""}:</h3>
              <span>
                £
                {cart.coupon_value
                  ? parseFloat(cart.coupon_value).toFixed(2)
                  : 0}
              </span>
            </li>
            <li>
              <h2>
                Total:
                <span>
                  £
                  {(
                    parseFloat(cart.price - cart.coupon_value) +
                    parseFloat(cart.dvlaFee) +
                    parseFloat(cart.vat) +
                    parseFloat(cart.fastCheckout) +
                    parseFloat(cart.plate_type)
                  ).toFixed(2)}
                </span>
              </h2>
            </li>
          </ul>
          <div className="order-checkout-card-title-img">
            <img src="/img/visa.png" />
          </div>
        </div>
        <div className="tpr">
          <PilotCheckout />
        </div>
      </div>
    </>
  );
}

export default CartLeft;
