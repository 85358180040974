import React from "react";

function Fail() {
  return (
    <div>
      <div className="thankyou-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="thank-content">
                <img src="/img/error-img.gif" />
                <h2>Failed</h2>
                <p>
                  We regret to inform you that there was an issue processing
                  your recent payment transaction. Unfortunately, the payment
                  was not successful. We apologize for any inconvenience this
                  may have caused. Please review the payment details you
                  provided to ensure their accuracy. If you believe this is an
                  error, or if you have any questions, our support team is here
                  to help.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fail;
