import React from "react";
import { Link } from "react-router-dom";
import CartLeft from "./cart/CartLeft";
import { useDispatch } from "react-redux";
import RegistrationForm from "./cart/RegistrationForm";
import SelfStep from "./cart/SelfStep";
import LastStep from "./cart/LastStep";

function SecureCheckout({ cart, manufactured }) {
  const dispatch = useDispatch();
  return (
    <div>
      <div className="SecureCheckout-tabs">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="secure-tabs">
                <div className="accordion" id="accordionExample">
                  <RegistrationForm cart={cart} manufactured={manufactured} />
                  <SelfStep cart={cart} manufactured={manufactured} />
                  <LastStep cart={cart} manufactured={manufactured} />
                </div>
              </div>
            </div>
            <CartLeft cart={cart} manufactured={manufactured} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecureCheckout;
