function extractNumber(inputString) {
  const match = inputString.match(/\d+/);
  if (match) {
    return match[0];
  }
  return null;
}

function removeSpaces(inputString) {
  return inputString.replace(/\s/g, "");
}

function oldPattern(number) {
  let firstThree = number.slice(0, 3);
  let lastThree = number.slice(-3);

  return firstThree + " " + lastThree;
}

function prefixPattern(plate) {
  const number = plate.match(/\d+/g);
  const first = plate.slice(0, 1);
  const lastThree = plate.slice(-3);
  return `${first}${number} ${lastThree}`;
}

function numberSpacing(route, number) {
  console.log(
    route == "view"
      ? number
          ?.toUpperCase()
          .replace(extractNumber(number), `${extractNumber(number)} `)
      : route == "view-old"
      ? oldPattern(number)
      : route == "view-premium"
      ? number
      : route === "view-prefix"
      ? prefixPattern(number)
      : number
  );
  return route == "view"
    ? number
        ?.toUpperCase()
        .replace(extractNumber(number), `${extractNumber(number)} `)
    : route == "view-old"
    ? oldPattern(number)
    : route == "view-premium"
    ? number
    : route === "view-prefix"
    ? prefixPattern(number)
    : number;
}

function removeFromLast(arr, end) {
  let len = arr.length;
  let newArr = [];
  for (let index = 0; index < len; index++) {
    if (index === end) break;
    newArr.push(arr[index]);
  }

  return newArr;
}
export {
  extractNumber,
  removeSpaces,
  removeFromLast,
  oldPattern,
  prefixPattern,
  numberSpacing,
};
