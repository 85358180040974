import { useEffect } from "react";

const usePageTitleAndMeta = (title, description, keywords) => {
  useEffect(() => {
    document.title = title;

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content = description;
    } else {
      const newMeta = document.createElement("meta");
      newMeta.name = "description";
      newMeta.content = description;
      document.head.appendChild(newMeta);
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.content = keywords;
    } else {
      const newMeta = document.createElement("meta");
      newMeta.name = "keywords";
      newMeta.content = keywords;
      document.head.appendChild(newMeta);
    }
  }, [title, description, keywords]);
};

export default usePageTitleAndMeta;
