import React from "react";

function About({ manufactured, number }) {
  return (
    <div>
      <div className="about-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="about-title">
                <h2>Question About Buying This Private Plate</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="about-accordion">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Can I assign&nbsp;<b>{number}</b> &nbsp;to my vehicle?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          This registration <b>{number}</b> can only be
                          transferred onto a vehicle registered{" "}
                          <b>{manufactured} </b>
                        </p>
                        <p>
                          DVLA specify that you cannot make your vehicle appear
                          newer than it really is. For example, you cannot
                          assign a registration issued in 2012 onto a vehicle
                          registered in 2005.
                        </p>
                        <p>
                          The vehicle must also require tax,be MOT testable (not
                          exempt) and not a 'Q' plate.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Whats Included in the price?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          The price above is the total price for you to own the
                          legal entitlement to the registration mark. The
                          registration will be issued on a certificate ready to
                          be assigned to a vehicle.
                        </p>
                        <p>We have two optional extras:</p>
                        <p>
                          <b>Number Plates -</b> We can also supply set of
                          physical number plates for your vehicle. A standard
                          set start from £29.99 and can be added when you make
                          your purchase. We also offer high quality 4D font and
                          various flag options.
                        </p>
                        <p>
                          <b>Fast Track Transfer -</b> If you choose our Fast
                          Track Transfer option when placing your order, we
                          conduct the legal transfer and register your new
                          registration with DVLA on your behalf and arrange the
                          transfer of your current TAX and MOT.
                        </p>
                        <p>
                          You can also choose you preferred transfer date. Our
                          service is stress & hassle free, we deal with all
                          complications, and there is no need for you to deal
                          with government forms.
                        </p>
                        <p>
                          If you do not have the V5C/Logbook yet or buying as a
                          gift then you can provide these details at a later
                          date via the special link we provide in the order
                          confirmation email.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        How long does it take?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          We offer a quick service for buying your new private
                          registration number. Although it is possible we can
                          process and complete your purchase in as quick as a
                          few days - we aim to process and complete most
                          purchases within 7-10 days or sooner.
                        </p>
                        <p>
                          Sales of pre-owned registration marks may take longer
                          where you are not opting for JDM Plates to undertake
                          the legal assignment to your vehicle, but we will keep
                          you informed of timescales as we process your order.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Can I buy this registration as a gift?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          You sure can! If you are purchasing this registration
                          as a gift, you can gift the recipient the certificate
                          for them to assign themselves or if you choose our
                          <strong> Fast Track Transfer</strong> option when
                          placing your order, we conduct the legal transfer and
                          register the new registration with DVLA on their
                          behalf and arrange the transfer of current TAX and
                          MOT.
                        </p>
                        <p>
                          You can also choose you preferred transfer date. Our
                          service is stress & hassle free, we deal with all
                          complications, and there is no need for you to deal
                          with government forms.
                        </p>
                        <p>
                          If you do not have the V5C/Logbook yet then
                          you/recipient can provide these details at a later
                          date via the special link we provide in the order
                          confirmation email.
                        </p>
                        <p>
                          To purchase this registration the only information we
                          require is the nominee name of the person that the
                          vehicle is registered to. You can tell us this during
                          the checkout process.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        I do not have a vehicle, can I still buy now?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          If you don't have a car yet, you can still purchase
                          the registration today, and assign it at a later date!
                          The registration <strong>{number}</strong> will be
                          issued on a certificate until you are ready to assign
                          to a suitable vehicle
                        </p>
                        <p>
                          If you choose our <b>Fast Track Transfer </b>
                          option when placing your order, we conduct the legal
                          transfer and register your new registration with DVLA
                          on your behalf and arrange the transfer of your
                          current TAX and MOT.
                        </p>
                        <p>
                          You can also choose you preferred transfer date. Our
                          service is stress & hassle free, we deal with all
                          complications, and there is no need for you to deal
                          with government forms.
                        </p>
                        <p>
                          If you do not have the V5C/Logbook yet or buying as a
                          gift then you can provide these details at a later
                          date via the special link we provide in the order
                          confirmation email.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        Can you transfer &nbsp;
                        <b>{number}</b>&nbsp; for me?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          We definitely can! We offer a{" "}
                          <b>Fast Track Transfer Service.</b>
                        </p>
                        <p>
                          This is our most popular option as it removes any
                          added legal hassle and makes the process much
                          smoother.
                        </p>
                        <p>
                          Stress-free. We deal with all the legal paperwork.
                          Transfer completed as quickly as possible or at a date
                          of your choice.
                          <br />
                          JDM Plates will update your TAX and MOT records.
                          <br />
                          No need to deal with the DVLA.
                          <br />
                          You can provide the V5C information after making
                          payment, or provide at a later date if you do not have
                          this yet.
                        </p>
                        <p>
                          If you are not ready for the assignment straight away
                          then you can select a preferred transfer date of your
                          choice.
                        </p>
                        <p>
                          This option is available during the checkout process,
                          please be sure to tick the box for our "
                          <b>Fast Track Transfer Service</b>" at checkout.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        I don’t wish to transfer &nbsp;
                        <b>{number}</b>&nbsp; to a vehicle immediately, can I
                        hold it?
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Yes, of course. JDM Plates can supply all registration
                          marks on a retention certificate. This option can be
                          selected during the checkout process and does NOT
                          incur any additional charges. Please note: The DVLA
                          transfer fee is always payable at the point of
                          purchase, but you will not be charged again when you
                          assign your registration mark at a later date.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        How do I buy &nbsp;
                        <b>{number}</b>?
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Buying a personalised registration with{" "}
                          <b>JDM Plates</b> is a pain free process!
                        </p>
                        <p>
                          1. Click the "<b>Secure Your Reg</b>" button to
                          proceed to checkout{" "}
                        </p>
                        <p>
                          2. During checkout you can select if you would like to
                          include a set of number plates for your registration.{" "}
                        </p>
                        <p>
                          3. You can select if you would like JDM Plates to
                          handle the DVLA legal transfer of the registration to
                          your vehicle, using our{" "}
                          <b>Fast Track Transfer Service</b> or assign to a
                          retention certificate for you to handle yourself.{" "}
                        </p>
                        <p>
                          4. Make your payment either by debit/credit card or
                          monthly finance. Once your order is received, our
                          dispatch team will work on completing it as quickly as
                          possible!
                        </p>
                        <p>
                          If you don't opt for our <b>Fast Track Transfer </b>
                          then we will post to you the certificate for your
                          registration which you can use to assign the
                          registration to your vehicle.{" "}
                        </p>
                        <p>
                          Once the legal transfer of the registration to a
                          vehicle has been complete, you can then display new
                          number plates on the vehicle.
                        </p>
                        <p>
                          For full details on the ordering process please see
                          our <b>Number Plate Ordering Guide</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        Can you help me sell my registration number
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          JDM Plates can advertise your personalised
                          registration on our website which receives thousands
                          of visitors every day.
                        </p>
                        <p>
                          To list your registration for sale, please complete
                          the listing form on this page Sell My Number Plate
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
